






import Vue from 'vue';
import { mapState } from 'vuex';

import TestingCentersTime from '@/components/TestingCenters/TestingCenterTime.vue';

export default Vue.extend({
  components: {
    TestingCentersTime,
  },
  computed: mapState(['appointmentTimes']),
  mounted() {
    this.$store.dispatch('getLabTimesByDay', {
      id: this.$store.state.currentTestingCenter.id,
      date: this.$store.state.currentAppointmentDate.date,
    });
  },
});
