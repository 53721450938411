










































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'TestingCenterConsent',
  components: {
    LoadingIndicator,
  },
  computed: {
    ...mapState([
      'currentTestingCenter',
      'currentTestingCenterConsent',
      'isLoading',
      'locale',
    ]),
    ...mapGetters(['currentConsentParts']),
    currentConsentWithLanguage: function () {
      return this.currentConsentParts(this.locale);
    },
    consentHeader() {
      const titleTemplate = this.$t('TestingCenterConsentTitle') as string;
      return titleTemplate.replace('%@', '');
    },
  },
  mounted() {
    this.$store.dispatch(
      'getLabConsentById',
      this.$store.state.currentTestingCenter.lab
    );
  },
  methods: {
    decline() {
      this.$store.dispatch('setActiveModal', 'declinedConsent');
      this.$router.push('/testing-centers');
    },
    signIn() {
      this.$store.dispatch('setActiveModal', null);
      window.onbeforeunload = function () {
        // removes leave site check
      };
      this.$store.dispatch(
        'setCurrentConsent',
        this.currentTestingCenterConsent
      );
      this.$auth.federatedSignIn();
    },
  },
});
