


















import Vue from 'vue';

import { formatDate } from '@/helpers/dates';
import { AppointmentTime } from '@/api/models/AppointmentTimes';

export default Vue.extend({
  name: 'TestingCenterTimeCard',
  props: {
    time: {
      type: Object as () => AppointmentTime,
    },
  },
  methods: {
    formatDate,
    setAppointmentTime(): void {
      if (this.time.available) {
        this.$store.dispatch('setCurrentAppointmentTime', this.time);
        this.$router.push('consent');
      }
    },
  },
});
