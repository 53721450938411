

































import Vue from 'vue';
import { mapState } from 'vuex';

import { TestingCentersByCity } from '@/api/models/TestingCenter';
import TestingCenterCard from '@/components/TestingCenters/TestingCenterCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Modal from '@/components/Modal.vue';

export default Vue.extend({
  components: {
    TestingCenterCard,
    LoadingIndicator,
    Modal,
  },
  props: {
    centers: {
      type: Object as () => TestingCentersByCity,
    },
  },
  computed: {
    ...mapState(['isLoading']),
    isCentersEmpty: function () {
      // If API call has finished and the centers object is empty
      // show retry button
      return (
        !this.isLoading &&
        (this.centers === null || !Object.keys(this.centers).length)
      );
    },
  },
  mounted() {
    this.$store.dispatch('clearCurrentTestingCenterInfo');
  },
  methods: {
    retry: function () {
      this.$store.dispatch('loadInitialData');
    },
  },
});
