

















import Vue from 'vue';
import { TestingCenter } from '@/api/models/TestingCenter';

export default Vue.extend({
  name: 'TestingCenterCard',
  props: {
    center: {
      type: Object as () => TestingCenter,
    },
  },
  computed: {
    dateRoute: function (): string {
      return `/testing-centers/${this.center.id}/day`;
    },
  },
  methods: {
    setCurrentTestingCenter(): void {
      this.$store.dispatch('setCurrentTestingCenter', this.center);
      this.$router.push(this.dateRoute);
    },
  },
});
