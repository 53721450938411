











































import Vue from 'vue';
import { mapState } from 'vuex';

import { formatDate, mmddyyyyToDate } from '@/helpers/dates';

import TestingCenterTimeCard from '@/components/TestingCenters/TestingCenterTimeCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  components: {
    TestingCenterTimeCard,
    LoadingIndicator,
  },
  props: {
    times: {
      type: Array,
    },
  },
  computed: {
    ...mapState(['isLoading', 'currentTestingCenter', 'locale']),
    isTimesEmpty: function () {
      // If API call has finished and the times object is empty
      // show retry button
      return (
        !this.isLoading &&
        (this.times === null || !Object.keys(this.times).length)
      );
    },
  },
  methods: {
    formatDate,
    mmddyyyyToDate,
    retry: function () {
      this.$store.dispatch('getLabTimesByDay', {
        id: this.$store.state.currentTestingCenter.id,
        date: this.$store.state.currentAppointmentDate.date,
      });
    },
  },
});
