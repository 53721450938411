<template>
  <div>
    <TestingCenterConsent />
  </div>
</template>

<script>
import TestingCenterConsent from '@/components/TestingCenterConsent/TestingCenterConsent';

export default {
  name: 'TestingCenterConsentPage',
  components: {
    TestingCenterConsent,
  },
};
</script>
