






import Vue from 'vue';
import { mapGetters } from 'vuex';
import TestingCenters from '@/components/TestingCenters/TestingCenters.vue';

export default Vue.extend({
  components: {
    TestingCenters,
  },
  computed: mapGetters(['testingCentersByCity']),
  mounted() {
    this.$store.dispatch('clearCurrentTestingCenterInfo');
  },
});
